import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useHolidays } from './useHolidays'
import { capitalize } from '@/utils/filter'
import { avatarText } from '@/utils/filter'
import { getUserData } from '../../../utils/utils'

import useAPI from '@/utils/useAPI'
import SearchFilter from '@/components/searchFilter/SearchFilter'
import i18n from '@/libs/i18n'
import ability from '../../../libs/acl/ability'
import router from '@/router'

export const mixinHolidays = {
  components: {
    SearchFilter,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'employee', label: i18n.tc('employee', 1), sortable: true },
      { key: 'startDate', label: i18n.t('start'), sortable: true },
      { key: 'endDate', label: i18n.t('end'), sortable: true },
      { key: 'status', label: i18n.t('status'), sortable: false },
    ])
    const selectedFilters = ref([])
    const currentHoliday = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { events } = useAPI()

    const holidays = computed(() => {
      return events.value.filter(e => {
        if (ability.can('manage', 'all')) {
          return e.type == 'holiday'
        } else {
          return e.type == 'holiday' && e.logs[0].by.id == getUserData().id
        }
      })
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(holidays, (val) => {
      if (val.length >= 1) {
        selectedFilters.value = ['_filter_isWaitingForValidation']
        enableFilters()
      } else {
        selectedFilters.value = []
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchEvents,
    } = useAPI()

    const {
      submitValidatedHoliday,
    } = useHolidays()

    const resetCurrentHoliday = () => {
      currentHoliday.value = null
    }

    const validateHoliday = (holiday) => {
      currentHoliday.value = JSON.parse(JSON.stringify(holiday))
      currentHoliday.value.isValidated = true

      submitValidatedHoliday(currentHoliday.value)
        .then(() => {
          resetCurrentHoliday()
        })
    }

    const declineHoliday = (holiday) => {
      currentHoliday.value = JSON.parse(JSON.stringify(holiday))
      currentHoliday.value.isValidated = false

      submitValidatedHoliday(currentHoliday.value)
        .then(() => {
          resetCurrentHoliday()
        })
    }

    const enableFilters = () => {
      // if (holidays.value.length) {
      holidays.value[0]._filters.forEach(filter => {
        if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
          additionalSortOptions.value.push(filter)
        }
      })
      // }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchEvents()

    return {
      // Components
      capitalize,
      avatarText,

      // Data
      additionalSortOptions,
      tableColumns,
      selectedFilters,

      // Computed
      holidays,

      // Methods
      validateHoliday,
      declineHoliday,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteHolidayAlert (employee) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theHoliday') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeHolidayLocal(employee)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}