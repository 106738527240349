var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('search-filter',{attrs:{"items":_vm.holidays,"keyTitleForAlphabeticalSort":'start',"additionalSortOptions":_vm.additionalSortOptions,"selectedView":'list',"selectedFilters":_vm.selectedFilters,"tableColumns":_vm.tableColumns,"isActionColumnEnabled":_vm.$can('manage', 'all'),"allowAdd":false},scopedSlots:_vm._u([{key:"gridView",fn:function(ref){
var item = ref.item;
return [_c('card-holiday',{attrs:{"holiday":item},on:{"validateHoliday":function($event){return _vm.validateHoliday(item)},"declineHoliday":function($event){return _vm.declineHoliday(item)}}})]}},{key:"listView_cell_employee",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":item.logs[0].by.avatar?item.logs[0].by.avatar.url:null,"text":_vm.avatarText(item.logs[0].by.firstName + ' ' + item.logs[0].by.name),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(item.logs[0].by._display)+" ")])],1)]}},{key:"listView_cell_startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.startDate,'LL'))+" "),(_vm.isMorning(item.startDate))?_c('span',[_vm._v(_vm._s(_vm.$t('startMorning')))]):_c('span',[_vm._v(_vm._s(_vm.$t('startAfternoon')))])]}},{key:"listView_cell_endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.endDate,'LL'))+" "),(_vm.isMorning(item.endDate))?_c('span',[_vm._v(_vm._s(_vm.$t('endMorning')))]):_c('span',[_vm._v(_vm._s(_vm.$t('endAfternoon')))])]}},{key:"listView_cell_status",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":'light-'+(item.isValidated?'success':(item.isValidated==false?'danger':'secondary'))}},[_c('icon',{attrs:{"icon":_vm.getIcon(item)}})],1)]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('manage', 'all'))?_c('div',{staticClass:"text-nowrap"},[_c('button-validate',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('validate')),expression:"$t('validate')",modifiers:{"hover":true,"left":true}}],attrs:{"disabled":item.isValidated == true,"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},on:{"click":function($event){return _vm.validateHoliday(item)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('cancel'))),expression:"capitalize($t('cancel'))",modifiers:{"hover":true,"left":true}}],attrs:{"disabled":item.isValidated != null,"withIcon":true,"withText":false,"withBorder":false,"size":"sm"},on:{"click":function($event){return _vm.declineHoliday(item)}}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }