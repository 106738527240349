<template>
  <div>

    <search-filter
        :items="holidays"
        :keyTitleForAlphabeticalSort="'start'"
        :additionalSortOptions="additionalSortOptions"
        :selectedView="'list'"
        :selectedFilters="selectedFilters"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="$can('manage', 'all')"
        :allowAdd="false"
    >
      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-holiday
            :holiday="item"
            @validateHoliday="validateHoliday(item)"
            @declineHoliday="declineHoliday(item)"
        />
      </template>

      <!--      List view-->

      <!--      Cell employee-->
      <template #listView_cell_employee="{item}">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
                size="32"
                :src="item.logs[0].by.avatar?item.logs[0].by.avatar.url:null"
                :text="avatarText(item.logs[0].by.firstName + ' ' + item.logs[0].by.name)"
                variant="light-primary"
            />
          </template>
          <b-link
              class="font-weight-bold d-block text-nowrap"
          >
            {{ item.logs[0].by._display }}
          </b-link>
        </b-media>
      </template>

      <!--      Cell Start-->
      <template #listView_cell_startDate="{item}">
        {{ item.startDate|moment('LL') }}
        <span v-if="isMorning(item.startDate)">{{ $t('startMorning') }}</span>
        <span v-else>{{ $t('startAfternoon') }}</span>
      </template>

      <!--      Cell End-->
      <template #listView_cell_endDate="{item}">
        {{ item.endDate|moment('LL') }}
        <span v-if="isMorning(item.endDate)">{{ $t('endMorning') }}</span>
        <span v-else>{{ $t('endAfternoon') }}</span>
      </template>

      <!--      Cell status-->
      <template #listView_cell_status="{item}">
        <b-badge
            pill
            :variant="'light-'+(item.isValidated?'success':(item.isValidated==false?'danger':'secondary'))"
            class="text-capitalize"
        >
          <icon :icon="getIcon(item)"/>
        </b-badge>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div v-if="$can('manage', 'all')"
             class="text-nowrap">

          <!--          Validate-->
          <button-validate
              @click="validateHoliday(item)"
              v-b-tooltip.hover.left="$t('validate')"
              :disabled="item.isValidated == true"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Remove-->
          <button-delete
              @click="declineHoliday(item)"
              v-b-tooltip.hover.left="capitalize($t('cancel'))"
              :disabled="item.isValidated != null"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>

    </search-filter>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinHolidays } from './mixinHolidays'

import moment from 'moment'
import ButtonValidate from '@/components/button/Validate'
import ButtonDelete from '@/components/button/Delete'
import CardHoliday from '@/components/card/Holiday'

export default {
  components: {
    ButtonValidate,
    ButtonDelete,
    CardHoliday
  },
  mixins: [mixinHolidays],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isMorning = (date) => {
      if (moment(date).format('HH') <= 12) {
        return true
      } else {
        return false
      }
    }

    const getIcon = (event) => {
      if (event.isValidated) {
        return 'check'
      } else if (event.isValidated == false) {
        return 'times'
      } else {
        return 'stopwatch'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
      isMorning,
      getIcon,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>