import store from '@/store'

export const useHolidays = () => {

  const submitValidatedHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      if ('id' in holiday) {
        updateHoliday(holiday)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewHoliday(holiday)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/addEvent', holiday)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/updateEvent', holiday)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/removeEvent', holiday)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // const getHolidayIconStatus = (holiday) => {
  //   if (getHolidayStatus(holiday) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
  //   else if (getHolidayStatus(holiday) == 'invoiced') return { variant: 'success', icon: 'file-invoice-dollar' }
  //   else return { variant: 'primary', icon: 'paper-plane' }
  // }

  return {
    submitValidatedHoliday,
    removeHoliday,
    // getHolidayIconStatus
  }
}