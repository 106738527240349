<template>
  <generic-card
      :allow-delete="false"
  >

    <template #iconContent>
      <b-avatar
          size="lg"
          variant="light-primary"
          :text="nameInitials"
          class="my-auto"
      />
    </template>

    <template #bodyContent>
      <h4>{{ reactiveDisplay }}</h4>

      <div class="text-truncate">
        <small>
          {{ holiday.startDate|moment('LL') }}
          <span v-if="isMorning(holiday.startDate)">{{ $t('startMorning') }}</span>
          <span v-else>{{ $t('startAfternoon') }}</span>
        </small>
      </div>
      <div class="text-truncate">
        <small>
          {{ holiday.endDate|moment('LL') }}
          <span v-if="isMorning(holiday.endDate)">{{ $t('endMorning') }}</span>
          <span v-else>{{ $t('endAfternoon') }}</span>
        </small>
      </div>
    </template>

    <template #actionContent>
      <!--          Validate-->
      <button-validate
          @click="$emit('validateHoliday')"
          v-b-tooltip.hover.left="$t('validate')"
          :disabled="!$can('manage', 'all') || holiday.isValidated == true"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <!--          Remove-->
      <button-delete
          @click="$emit('declineHoliday')"
          v-b-tooltip.hover.left="capitalize($t('cancel'))"
          :disabled="!$can('manage', 'all') || holiday.isValidated != null"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />
    </template>
  </generic-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import GenericCard from './Card'
import ButtonValidate from '@/components/button/Validate'
import ButtonDelete from '@/components/button/Delete'
import moment from 'moment'

export default {
  components: {
    GenericCard,
    ButtonValidate,
    ButtonDelete
  },
  props: {
    holiday: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const nameInitials = computed(() => {
      let fullName = JSON.parse(JSON.stringify(props.holiday.logs[0].by.firstName)) + ' ' + JSON.parse(JSON.stringify(props.holiday.logs[0].by.name))
      let initials = fullName.split(' ')

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }

      return initials.toUpperCase()
    })

    const reactiveDisplay = computed(() => {
      return props.holiday.logs[0].by.firstName + ' ' + props.holiday.logs[0].by.name
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isMorning = (date) => {
      if (moment(date).format('HH') <= 12) {
        return true
      } else {
        return false
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      nameInitials,
      reactiveDisplay,

      // Methods
      isMorning,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>